@import "./settings";

// Spacing Utility Classes
// These apply margin/padding in 20px increments
// e.g.:
//   - mt-1 applies 20px of top margin
//   - pb-4 applies 80px of bottom padding
//
// They can include a breakpoint suffix, to apply at the given
// breakpoint (or larger):
//
//   - mt-1-md Applies 20px of top margin at "md" and "lg"
//   - pb-4-xs Applies 40px of bottom padding at "xs", "sm", "md", "lg", above

@mixin spacing-util-classes($breakpoint: "") {
  $sides: (
    "": "",
    "t": "-top",
    "b": "-bottom",
    "l": "-left",
    "r": "-right"
  );

  $increment: 20px;

  @for $i from 0 through 5 {
    @each $abbrev, $side in $sides {
      $marginSelector: ".m#{$abbrev}-#{$i}";
      $paddingSelector: ".p#{$abbrev}-#{$i}";

      @if ($breakpoint != "") {
        $marginSelector: "#{$marginSelector}-#{$breakpoint}";
        $paddingSelector: "#{$paddingSelector}-#{$breakpoint}";
      }

      #{$marginSelector} {
        margin#{$side}: $i * $increment !important;
      }

      #{$paddingSelector} {
        padding#{$side}: $i * $increment !important;
      }
    }
  }
}

@mixin display-util-classes($breakpoint: "") {
  $selector: ".hide";

  @if ($breakpoint != "") {
    $selector: "#{$selector}-#{$breakpoint}";
  }

  #{$selector} {
    display: none !important;
  }
}

@include spacing-util-classes;
@include display-util-classes;

@include xs-only {
  @include spacing-util-classes("xs");
  @include display-util-classes("xs");
}

@include sm-only {
  @include spacing-util-classes("sm");
  @include display-util-classes("sm");
}

@include md-only {
  @include spacing-util-classes("md");
  @include display-util-classes("md");
}

@include lg-only {
  @include spacing-util-classes("lg");
  @include display-util-classes("lg");
}

@include xl-only {
  @include spacing-util-classes("xl");
  @include display-util-classes("xl");
}

.align-right {
  text-align: right;
}
