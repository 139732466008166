@import "../../settings.scss";

.event {
  color: $black;
  text-decoration: none;
  @include hover {
    color: $black;
    text-decoration: none;
  }
  &:active {
    color: $black;
  }

  display: flex;
  flex-direction: column;

  &__title {
    font-weight: bold;
    font-size: 17px;
    line-height: 24px;

    &--link {
      color: $green;
      transition: color linear 0.3s;
      @include hover {
        color: $dark-green;
      }
      &::after {
        display: inline-block;
        content: "";
        width: 16px;
        height: 16px;
        background: url(./images/external.svg) no-repeat;
        margin-left: 5px;
      }
    }
  }

  @mixin body-small {
    font-size: 14px;
    line-height: 19px;
  }
  &__organization {
    font-weight: bold;
    @include body-small;
  }

  &__description {
    @include body-small;
  }
  &__location {
    @include body-small;
    order: -1;
    margin-bottom: 8px;
  }
  &__separator {
    display: inline-block;
    margin: 0 10px;
  }

  &__tags {
    order: -2;
    margin-bottom: 4px;
  }
  &__tag {
    @include body-small;
    font-weight: bold;
    display: inline-block;

    color: $white;
    background-color: $dark-teal;

    padding: 2px 10px;
    margin-right: 8px;
    margin-bottom: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.accordion .event {
  background-color: $lighter-grey;
  border-bottom: 2px solid $white;
  padding: 17px 32px 21px;
}
