@import "../../settings.scss";

@mixin playButton {
  background: url(./images/play-button.png);
  background-repeat: none;
  background-size: cover;
  border: none;
  width: 101px;
  height: 101px;

  transition: transform 0.25s;
}
@mixin playButtonHover {
  background-color: transparent;
  transform: scale(1.1);
}

.vjs-theme-spark {
  &.video-js {
    background-color: transparent;
    .vjs-poster {
      background-color: transparent;
    }
    .vjs-big-play-button {
      @include playButton;
    }

    @include hover {
      .vjs-big-play-button {
        @include playButtonHover;
      }
    }
  }
}
