@import "../../settings";
.background-video {
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;

  &__video {
    background-color: $black;
    width: 100%;
  }
  @include sm {
    width: 100%;
    // height: 70vh;

    &__video {
      width: auto;
      height: 100%;
    }
  }
}
