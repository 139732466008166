// NOTE: This file is included repeatedly by component .scss files
//
// As such, it can only contain:
//   - variables
//   - mixins
//
// Any CSS rules must be put in another file.
//
// Think of it like this: If you were to compile *only* this file from SASS to
// CSS, the output should be an empty file (because this file should contain
// only SASS-relevant code).

$font-weight-semibold: 600;

$font-size-body: 17px;
$font-size-body-small: 14px;

$font-size-h1: 56px;
$font-size-h3: 22px;
$font-weight-h3: bold;

// global settings
$dark-blue: #086199;
$medium-blue: #00629b;
$blue: #1091ba;
$dark-teal: #0092bc;
$light-teal: #7fc8dd;
$grey: #6d6e71;
$light-grey: #f2f2f2;
$lighter-grey: #f7f7f7;
$dark-grey: #5d666f;
$white: #fff;
$black: #000;
$green: #43b02a;
$dark-green: #1c9600;

$label: #444444;
$placeholder: #808080;
$error: #ff000a;
$error-bg: #ffd9da;

$valid-bg: #e3f3df;

$opacity-disabled-control: 0.3;

$body-font-family: "proxima-nova", Verdana, Arial, Helvetica, sans-serif;
$body-font-color: #444;

$font-size-body: 18px;
$font-size-small: 12px;

$z-index-floating-labels: 1;
$z-index-nav: 10;
$z-index-dropdowns: 100000;
$z-index-mobile-nav: 5000;
$z-index-modal: 100000; // Must be above regulator bracket z-index

// Breakpoint mixins
// These breakpoints are taken from the flexboxgrid library, which we use for
// our responsive grid. If you modify them, you will also need to modify
// flexboxgrid. Additionally, these breakpoints are included Javascript
// (for useBreakpoint() effect), so you'll need to update them there as well.
$breakpoints: (
  "xxs": 375px,
  "xs": 576px,
  "sm": 768px,
  "md": 992px,
  "lg": 1200px
);

@mixin xxs {
  @media only screen and (max-width: map-get($breakpoints, "xxs") - 1px) {
    @content;
  }
}

@mixin xs {
  @media only screen and (max-width: map-get($breakpoints, "xs") - 1px) {
    @content;
  }
}

@mixin xs-only {
  @media only screen and (max-width: map-get($breakpoints, "xs") - 1px) {
    @content;
  }
}

@mixin sm {
  @media only screen and (max-width: map-get($breakpoints, "sm") - 1px) {
    @content;
  }
}

@mixin sm-only {
  @media only screen and (min-width: map-get($breakpoints, "xs")) and (max-width: map-get($breakpoints, "sm") - 1px) {
    @content;
  }
}

@mixin md {
  @media only screen and (max-width: map-get($breakpoints, "md") - 1px) {
    @content;
  }
}

@mixin md-only {
  @media only screen and (min-width: map-get($breakpoints, "sm")) and (max-width: map-get($breakpoints, "md") - 1px) {
    @content;
  }
}

@mixin md-and-up {
  @media only screen and (min-width: map-get($breakpoints, "md")) {
    @content;
  }
}

@mixin lg {
  @media only screen and (max-width: map-get($breakpoints, "lg") - 1px) {
    @content;
  }
}

@mixin lg-only {
  @media only screen and (min-width: map-get($breakpoints, "md")) and (max-width: map-get($breakpoints, "lg") - 1px) {
    @content;
  }
}

@mixin lg-and-up {
  @media only screen and (min-width: map-get($breakpoints, "lg")) {
    @content;
  }
}

@mixin xl-only {
  @media only screen and (min-width: map-get($breakpoints, "lg")) {
    @content;
  }
}

@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}
