@import "../../settings";
@import "../../utility";

.accordion {
  padding: 1.5em 0;

  &[data-reach-tabs] {
    box-shadow: none;
  }

  [data-reach-tab-list] {
    display: flex;
    flex-direction: column;
  }

  &__heading {
    border: none;
    text-align: left;
    color: $green;
    padding: 0;
  }

  &__button {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  &__title {
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 0;
    }
  }

  &__chevron {
    min-width: 14px;
    width: 14px;
    transform: rotate(180deg);
    transition: transform 0.35s ease-in, stroke 0.35s ease-out;
    stroke: $white;
  }

  [aria-expanded="true"] &__chevron {
    transform: rotate(360deg);
  }

  &__panel {
    display: grid;
    transition: grid-template-rows 0.3s ease;
    grid-template-rows: 1fr;

    .accordion__panel-inner {
      overflow: hidden;
    }

    &[hidden] {
      grid-template-rows: 0fr;
      padding-bottom: 0;
    }

    @include xxs {
      max-height: 144px;
    }

    ul.pr {
      padding-right: 140px;

      @include md {
        padding-right: 0;
      }
    }
  }
  .item-pompe .accordion__panel {
    max-height: 752px;
    @include sm {
      max-height: 925px;
    }
    @include xs {
      max-height: 1325px;
    }
    @include xxs {
      max-height: 1540px;
    }

    &[hidden] {
      max-height: 0;
    }
  }

  &__footer {
    background: #f7f7f7;
    margin-bottom: -90px;
    margin-left: -50px;
    margin-right: -50px;
    margin-top: 40px;
    padding: 50px;

    @include md {
      margin: 20px -24px -90px;
    }
  }

  hr {
    margin: 0;
  }

  &--dark {
    $dark-border-color: #979797;
    $transparent-dark-border-color: #9797975a;
    .accordion {
      &__heading {
        background-color: $dark-teal;
        color: $white;
      }
      &__button {
        padding: 35px 32px;
        border-color: transparent;
        transition: border-color 0.35s;
      }

      &__chevron {
        width: 28px;
        height: 28px;

        transform: rotate(0deg);
      }
    }
    [aria-expanded="true"] {
      &.accordion__button {
        background-color: $white;
        color: $black;

        border-width: 1px 0 1px 0;
        border-style: solid;

        border-color: transparent transparent $transparent-dark-border-color
          transparent;
      }

      .accordion__chevron {
        stroke: $dark-green;
        transform: rotate(180deg);
      }
    }

    .accordion__item:first-child [aria-expanded="true"].accordion__button {
      border-color: $dark-border-color transparent
        $transparent-dark-border-color transparent;
    }
    hr {
      border-top-color: #ffffff;
      border-top-width: 2px;
    }
  }
}
