@import "../../settings";

@mixin hero {
  h1 {
    line-height: 1.25;
    @include sm {
      font-size: 44.8px;
      line-height: 1;
    }
    margin: 0;
  }

  .lede {
    font-size: 24px;
    line-height: 28px;
  }
}
.dna-hero {
  @include hero;
  $gradient: linear-gradient(-45deg, #7fb0cd 0%, #0092bc 22%, #00629b 100%);

  background-image: url(images/helix-left.svg), url(images/helix-right.svg),
    $gradient;
  background-repeat: no-repeat;
  background-position: left top, right bottom, center center;
  background-size: contain;

  padding: 100px 0 130px 0;
  @include md {
    background-image: url(images/helix-left.svg), $gradient;
    background-position: left bottom, center center;
    background-size: contain;
  }

  @include sm {
    background-image: url(images/helix-left.svg), $gradient;
    background-position: left bottom, center center;
    background-size: cover;
    padding: 75px 0 95px 0;

    h1 {
      font-size: 38px;
      line-height: 1;
    }
  }
}

.aav-hero {
  @include hero;
  padding: 100px 0 80px;
  // background-image: url(images/aav-still.png);
  // background-repeat: no-repeat;
  // background-size: contain;

  position: relative;
  .background-video {
    z-index: 0;
  }
  .container-fluid {
    z-index: 1;
    position: relative;
  }
}
