.icon-row {
  display: flex;

  &--top {
    align-items: flex-end;
  }

  &__icon {
    flex: 0 0 67px;
  }
}
